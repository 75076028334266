import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import PETSApp from '../views/app/PETSApp.vue'
import ImpressumView from '../views/impressum/ImpressumView.vue'
import PrintView from '../views/print/PrintView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
//   {
//     path: '/app',
//     name: 'rouappter',
//     component: PETSApp
//   },
  {
    path: '/print',
    name: 'print',
    component: PrintView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView,
    props: { section: 'imp' }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: ImpressumView,
    props: { section: 'privacy' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
