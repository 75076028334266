<template>
  <router-view/>
</template>

<style lang="scss">
    @import "./scss/_styles.scss";

    //-- OPEN SANS -----------------
    // prettier-ignore
    @font-face {
        font-family: "OpenSans";
        src: local("OpenSans"), url(./fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans-Light";
        src: local("OpenSans-Light"), url(./fonts/OpenSans/OpenSans-Light.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans-Medium";
        src: local("OpenSans-Medium"), url(./fonts/OpenSans/OpenSans-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans-SemiBold";
        src: local("OpenSans-SemiBold"), url(./fonts/OpenSans/OpenSans-SemiBold.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans-Bold";
        src: local("OpenSans-Bold"), url(./fonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans-ExtraBold";
        src: local("OpenSans-ExtraBold"), url(./fonts/OpenSans/OpenSans-ExtraBold.ttf) format("truetype");
    }
    //-- OPEN SANS CONDENSED -------
    @font-face {
        font-family: "OpenSans_Condensed";
        src: local("OpenSans_Condensed-Regular"), url(./fonts/OpenSans_Condensed/OpenSans_Condensed-Regular.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans_Condensed-Light";
        src: local("OpenSans_Condensed-Light"), url(./fonts/OpenSans_Condensed/OpenSans_Condensed-Light.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans_Condensed-Medium";
        src: local("OpenSans_Condensed-Medium"), url(./fonts/OpenSans_Condensed/OpenSans_Condensed-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans_Condensed-SemiBold";
        src: local("OpenSans_Condensed-SemiBold"), url(./fonts/OpenSans_Condensed/OpenSans_Condensed-SemiBold.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans_Condensed-Bold";
        src: local("OpenSans_Condensed-Bold"), url(./fonts/OpenSans_Condensed/OpenSans_Condensed-Bold.ttf) format("truetype");
    }
    @font-face {
        font-family: "OpenSans_Condensed-ExtraBold";
        src: local("OpenSans_Condensed-ExtraBold"), url(./fonts/OpenSans_Condensed/OpenSans_Condensed-ExtraBold.ttf) format("truetype");
    }
    //------------------------------

    html {
        width: 100%;
        height: 100%;
        background-color: $coal;
    }

    body {
        width: 100%;
        height: 100%;
        font-size: $font-size;
    }

    @media print {
        @page {
            size: A4;
            margin: 0;
        }

        html, body {
            background-color: #fff !important;
        }
    }

    html,
    body,
    div,
    p,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    table,
    tr,
    th
    li {
        padding: 0;
        margin: 0;
    }

    a, a:hover {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    table {
        font-family: $ff-text;
    }

    #app {
        width: 100%;
        height: 100%;
        font-family: $ff-text;
        //-webkit-font-smoothing: antialiased;
        // -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: var(--text-color);
    }
</style>
