<template>
    <div class="main-container">
        <Header class="no-print" :cur-page="'impressum'">
        </Header>

        <div class="impressum-container container">

            <div class="impressum content" id="imp" v-if="section == 'imp'">
                <section class="section">
                    <h2>Impressum</h2>
                </section>

                <section class="section left">
                    <h3>Verwantwortlicher für den Inhalt gemäß § 55 Abs. 2 RStV</h3>
                    <p>
                        {{impressumData.name}}<br>
                        {{impressumData.location}}<br>
                        {{impressumData.street}}<br>
                        {{impressumData.address}},
                        {{impressumData.country}}
                    </p>
                </section>

                <section class="section left">
                    <h3>Kontakt</h3>
                    <p>
                        {{impressumData.phone}}<br>
                        <a class="link" v-bind:href="'mailto:'+impressumData.email">{{impressumData.email}}</a>
                    </p>
                </section>
                <section class="section left">
                    <h3>Haftungsausschluss (Disclaimer)</h3>

                    <h4>Haftung für Inhalte</h4>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                        zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                        diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
                        Inhalte umgehend entfernen.
                    </p>

                    <h4>Haftung für Links</h4>
                    <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                        haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
                        der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                        umgehend entfernen.</p>

                    <h4>Urheberrecht</h4>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                        außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                        bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                        Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden
                        die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                        umgehend entfernen.</p>
                </section>
            </div>

            <div class="privacy content" id="privacy" v-if="section == 'privacy'">
                <section class="section">
                    <h2>Datenschutzerklärung</h2>
                </section>
                <section class="section left">
                    <p>
                        Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der
                        Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
                        Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
                    </p>

                    <p>
                        Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
                        (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter
                        Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
                        Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung
                        oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch übermittlung,
                        Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
                        Einschränkung, das Löschen oder die Vernichtung.
                    </p>
                    <p>
                        Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck,
                        Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder
                        gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir
                        Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
                        eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung
                        verarbeiten.
                    </p>
                    <p>
                        Unsere Datenschutzerklärung ist wie folgt gegliedert:
                        <br/>
                        I. Informationen über uns als Verantwortliche
                        <br/>
                        II. Rechte der Nutzer und Betroffenen
                        <br/>
                        III. Informationen zur Datenverarbeitung
                    </p>
                </section>
                <section class="section left">
                    <h3>I. Informationen über uns als Verantwortliche</h3>
                    <p>Verantwortlicher Anbieter und Datenschutzbeauftragter dieses Internetauftritts im
                        datenschutzrechtlichen Sinne ist:
                    </p>
                    <p>
                        {{impressumData.name}}<br/>
                        {{impressumData.location}}<br />
                        {{impressumData.street}}<br />
                        {{impressumData.address}},
                        {{impressumData.country}}<br/>

                        {{impressumData.phone}}<br/>
                        <a class="link" v-bind:href="'mailto:'+impressumData.email">{{impressumData.email}}</a>
                    </p>
                </section>

                <section class="section left">
                    <h3>II. Rechte der Nutzer und Betroffenen</h3>
                    <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und
                        Betroffenen folgende Rechte.
                    </p>
                    <ul>
                        <li>Recht auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die
                            verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der
                            Daten (vgl. auch Art. 15 DSGVO).
                        </li>
                        <li>Recht auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch
                            Art. 16 DSGVO).
                        </li>
                        <li>Recht auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder,
                            alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf
                            Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO.
                        </li>
                        <li>Recht auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf übermittlung
                            dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO).
                        </li>
                        <li>Recht auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie
                            betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen
                            verarbeitet werden (vgl. auch Art. 77 DSGVO).
                        </li>
                    </ul>
                    <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den
                        Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die
                        Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu
                        unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit
                        einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf
                        Auskunft über diese Empfänger.
                    </p>
                    <p>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die
                        künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe
                        von Art. 6 Abs. 1 lit. e oder  f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die
                        Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</p>
                </section>
                <section class="section left">
                    <h3>III. Informationen zur Datenverarbeitung</h3>
                    <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald
                        der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
                        entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren
                        gemacht werden.
                    </p>
                    <h4>Serverdaten</h4>
                    <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen
                        Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren
                        Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres
                        Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt
                        gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum
                        und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die
                        Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
                    <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
                    <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>
                <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
                    <p>Diese Datenschutzerklärung basiert auf der Muster-Datenschutzerklärung der <a target="_blank" class="link" href="https://www.ratgeberrecht.eu/">Anwaltskanzlei Weiß & Partner</a></p>
                </section>
            </div>
        </div>

        <Footer class="no-print">
        </Footer>
    </div>
</template>



<script setup lang="ts">
import { defineComponent, onMounted, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";

const props = defineProps({
    section: {
        type: String,
        required: true,
    },
});
const route = useRoute();
const impressumData = {
    name: 'Matthias Schmidmaier',
    location: 'LFE Medieninformatik',
    street: 'Frauenlobstr. 7a | 4. Stock',
    address: '80337 München',
    country: 'Germany',
    email: 'matthias.schmidmaier@ifi.lmu.de',
    phone: '+49 (89) 45237588'
}

// onMounted(() => {
//     scrollToPageSection(props.section);
// });

// const scrollToPageSection = (label: string) => {
//     const targetSection = document.getElementById(label);
//     if (targetSection) {
//         targetSection.scrollIntoView({ behavior: "smooth" });
//     } else {
//         window.scrollTo({ top: 0, behavior: "smooth" });
//     }
// }

</script>


<style lang="scss" scoped>
  @import "./impressum.scss";
</style>
