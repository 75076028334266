import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faCoffee,
    faFilePdf,
    faShuffle,
    faSliders,
    faPen,
    faCopy,
    faCircleDown,
    faFileZipper,
    faListOl,
    faPrint,
    faHouse,
    faInfo,
    faInfoCircle,
    faCircle as fasCircle
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircle as farCircle
} from '@fortawesome/free-regular-svg-icons';
import { faCreativeCommons, faCreativeCommonsBy } from '@fortawesome/free-brands-svg-icons';
import { createRouter } from 'vue-router';

library.add(
    faFilePdf,
    faCoffee,
    faCreativeCommons,
    faCreativeCommonsBy,
    faShuffle,
    faSliders,
    faPen,
    faCopy,
    faCircleDown,
    faFileZipper,
    faListOl,
    faPrint,
    faHouse,
    faInfoCircle,
    farCircle,
    fasCircle
)

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .mount("#app");
