<template>
    <footer class="footer">

        <div class="links">
            <a @click="navigateTo('/')">Home</a>
            <a @click="navigateTo('/impressum')">Impressum</a>
            <a @click="navigateTo('/privacy')">Datenschutzerkl&auml;rung</a>
        </div>
    </footer>
</template>


<script setup lang="ts">
    import { routerKey, useRouter} from "vue-router";
    const router = useRouter()

    function navigateTo(r: string) {
        router.push(r);
    }

</script>

<style scoped lang="scss">
    @import "./footer.scss";
</style>
