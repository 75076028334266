<template>
    <header :class="'header'">
        <div class="background">
            <!--<img src="@/assets/pets_bg_col.png" />-->
        </div>

        <!-- <div class="logo">
            <PetsLogo class="svg-logo"
            :fill="'#f9f9f9'"
            :height="'40px'">
        </PetsLogo>
        </div> -->

        <div class="title-menu-block">
            <div class="title">
                <h1>PETS</h1>
                <h2>Perceived Empathy of Technology Scale</h2>
            </div>

            <div class="menu">
                <div class="buttons">
                    <button :class="'btn' + (props.curPage == 'home' ? ' active' : '')" @click="navigateTo('/')">
                        <font-awesome-icon class="icon" :icon="['fa', 'house']" />
                        <font-awesome-icon class="icon dot" :icon="['fas', 'circle']" />
                    </button>
                    <button :class="'btn' + (props.curPage == 'print' ? ' active' : '')" @click="navigateTo('/print')">
                        <font-awesome-icon class="icon" :icon="['fa', 'print']" />
                        <font-awesome-icon class="icon dot" :icon="['fas', 'circle']" />
                    </button>
                    <button :class="'btn' + (props.curPage == 'impressum' ? ' active' : '')" @click="navigateTo('/impressum')">
                        <font-awesome-icon class="icon" :icon="['fa', 'info-circle']" />
                        <font-awesome-icon class="icon dot" :icon="['fas', 'circle']" />
                    </button>
                </div>
            </div>
        </div>
    </header>

</template>




<script setup lang="ts">
    import { defineEmits,defineProps, defineComponent, ref, onMounted, watch, computed} from "vue";
    import { routerKey, useRouter } from "vue-router";
    import PetsLogo from "@/components/petslogo/PetsLogo.vue";

    const router = useRouter()
    const emit = defineEmits(['onMenu'])
    const props = defineProps({
        curPage: String
    })

    onMounted(() => {
        const hash = window.location.hash;
        if (hash) {
            const anchor = hash.split('#')[1];
            scrollToAnchor(anchor);
        }
    });


    function navigateTo(r: string) {
        router.push(r);
    }

    function scrollToAnchor(anchor: string) {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "./header.scss";
</style>
