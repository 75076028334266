<template>
    <div class="main-container">
        <Header :cur-page="'home'">
        </Header>

        <div class="home container">
            <div class="content">


                <div class="block">
                    <p>
                        The PETS is a scientific scale that allows to measure
                        how empathic a system is perceived by the user.
                        PETS is freely available under the Creative Commons BY license.
                        Please refer to our <a @click="scrollToAnchor('publication')">publication</a>, which
                        describes the development and validation of PETS.
                        PETS was published at the ACM 2024 CHI Conference on Human Factors in Computing Systems and received an Honorable Mention.
                    </p>
                </div>

                <div class="block scale">
                    <h2>Scale Items</h2>
                    <p>
                        The PETS consists of ten items and two covarying factors
                        <span class="hl">ER (Emotional Responsiveness)</span>
                        and <span class="hl">UT (Understanding &amp; Trust)</span>.
                        To evaluate a system, randomize the PETS item order
                        and ask study participants to rate how they perceived the system
                        after interaction.
                        PETS was designed to be used with 101-point rating scales or sliders
                        from 'strongly disagree' to 'strongly agree'.
                    </p>
                    <div class="pets-tables">
                        <table class="pets-table top">
                            <tr class="row title">
                                <th>ER</th>
                                <th>Emotional Responsiveness</th>
                            </tr>
                            <tr class="row">
                                <td class="index">E1</td>
                                <td class="text">The system considered my mental state.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">E2</td>
                                <td class="text">The system seemed emotionally intelligent.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">E3</td>
                                <td class="text">The system expressed emotions.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">E4</td>
                                <td class="text">The system sympathized with me.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">E5</td>
                                <td class="text">The system showed interest in me.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">E6</td>
                                <td class="text">The system supported me in coping with an emotional situation.</td>
                            </tr>
                        </table>
                        <table class="pets-table btm">
                            <tr class="row title">
                                <th>UT</th>
                                <th>Understanding and Trust</th>
                            </tr>
                            <tr class="row">
                                <td class="index">U1</td>
                                <td class="text">The system understood my goals.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">U2</td>
                                <td class="text">The system understood my needs.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">U3</td>
                                <td class="text">I trusted the system.</td>
                            </tr>
                            <tr class="row">
                                <td class="index">U4</td>
                                <td class="text">The system understood my intentions.</td>
                            </tr>
                        </table>
                    </div>
                    <div class="legend">
                        <p>
                            <font-awesome-icon class="icon" :icon="['fa', 'shuffle']" />
                            &nbsp; randomize item order
                        </p>
                        <p>
                            <font-awesome-icon class="icon" :icon="['fa', 'sliders']" />
                            &nbsp; use 0-100 point sliders from 'strongly disagree' to 'strongly agree'
                        </p>
                        <p>
                            <font-awesome-icon class="icon" :icon="['fa', 'pen']" />
                            &nbsp; conduct after interaction with a system
                        </p>
                        <p>
                            <font-awesome-icon class="icon" :icon="['fab', 'creative-commons']" />
                            &nbsp; CC BY 4.0
                        </p>
                        <p>
                            <font-awesome-icon class="icon" :icon="['fab', 'creative-commons-by']" />
                            &nbsp; attribution required

                        </p>
                    </div>
                </div>


                <div class="block templates">
                    <h2>Application &amp; Scoring</h2>
                    <div class="text">
                        <p>
                            We recommend to use PETS with 101-point sliders in digital survey tools and randomized item
                            order.
                            If you want to carry out a PETS evaluation on paper, use our
                            <a @click="navigateTo('/print')">PETS printout generator</a>.
                        </p>
                        <p>
                            For scoring, we propose to calculate a mean per subscale and to calculate the
                            total score of the PETS weighted by the number of items per subscale so that values
                            from 0 to 100 can be obtained on the subscale scores as well as on the total score.
                            We also suggest referring to the PETS scores of our four validation scenarios as described in our paper.
                        </p>
                        <p class="equation">
                            PETS &equals; PETS<sub>ER</sub> &times; 0.6 &plus; PETS<sub>UT</sub> &times; 0.4<br>
                            PETS<sub>ER</sub> &equals;
                                (E<sub>1</sub>&plus;E<sub>2</sub>&plus;E<sub>3</sub>&plus;E<sub>4</sub>&plus;E<sub>5</sub>&plus;E<sub>6</sub>) &divide; 6<br>
                            PETS<sub>UT</sub> &equals;
                                (U<sub>1</sub>&plus;U<sub>2</sub>&plus;U<sub>3</sub>&plus;U<sub>4</sub>) &divide; 4<br>
                        </p>
                    </div>
                </div>

                <div class="block publication" id="publication">
                    <h2>Publication</h2>
                    <p>
                        Please cite the following publication when you use PETS.
                        Below you can download the full paper as PDF and supplementary material: the scenarios and
                        interview guide we used for item generation.
                    </p>

                    <div class="citation">
                        <a href="https://scholar.google.de/citations?user=3v9RqtYAAAAJ&hl=de">Matthias Schmidmaier</a>,
                        <a
                            href="https://www.uibk.ac.at/smt/innovation-entrepreneurship/team/jonathan-rupp/index.html.en">Jonathan
                            Rupp</a>,
                        Darina Cvetanova and
                        <a href="https://sven-mayer.com">Sven Mayer</a>. 2024. Perceived Empathy of Technology Scale
                        (PETS): Measuring Empathy of Systems Toward the User.
                        In Proceedings of the 2024 CHI Conference on Human Factors in Computing Systems (Honolulu,
                        Hawaii, USA) (CHI '24). Association for Computing Machinery, New York, NY,
                        <a href="https://doi.org/10.1145/3613904.3642035">https://doi.org/10.1145/3613904.3642035</a>
                    </div>

                    <div class="links">
                        <div class="link-row">
                            <a href="/downloads/schmidmaier2024perceived.pdf" download>
                                <div class="download-link pdf">
                                    <font-awesome-icon class="icon pdf" :icon="['fa', 'file-pdf']" />
                                    <div class="label">Paper</div>
                                </div>
                            </a>
                            <a href="https://doi.org/10.1145/3613904.3642035">
                                <div class="download-link doi">
                                    <img class="img" src="@/assets/doi.svg" />
                                    <div class="label">DOI</div>
                                </div>
                            </a>
                            <a href="/downloads/PETS_scenarios.zip" download>
                                <div class="download-link zip">
                                    <font-awesome-icon class="icon zip" :icon="['fa', 'file-zipper']" />
                                    <div class="label">PETS Scenarios</div>
                                </div>
                            </a>
                            <a href="/downloads/PETS_InterviewGuide.pdf" download>
                                <div class="download-link pdf">
                                    <font-awesome-icon class="icon pdf" :icon="['fa', 'file-pdf']" />
                                    <span class="label">Interview Guide</span>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div class="pub-row">
                        <div class="bibtex">
                            <div class="bibtex-head">
                                <p>BibTex</p>

                                <button class="btn clear" @click="copyBibTex()">
                                    <font-awesome-icon class="pdf-icon" :icon="['fa', 'copy']" />
                                </button>
                            </div>
                            <div class="bibtex-cite" id="bibtexcode" ref="bibtexcode">
                                @inproceedings{schmidmaier2024perceived,<br />
                                title = {Perceived Empathy of Technology Scale (PETS): Measuring Empathy of Systems
                                Toward the User},<br />
                                author = { Matthias Schmidmaier and Jonathan Rupp and Darina Cvetanova and Sven
                                Mayer},<br />
                                url = {https://perceived-empathy-of-technology-scale.com/},<br />
                                doi = {10.1145/3613904.3642035},<br />
                                year = {2024},<br />
                                date = {2024-05-11},<br />
                                urldate = {2024-05-11},<br />
                                booktitle = {Proceedings of the 2024 CHI Conference on Human Factors in Computing
                                Systems},<br />
                                publisher = {Association for Computing Machinery},<br />
                                address = {New York, NY, USA},<br />
                                series = {CHI \'24},<br />
                                tppubtype = {inproceedings}<br />
                                }
                            </div>
                        </div>

                        <div class="abstract">
                            <p>Abstract</p>
                            <div class="abstract-text">
                                Affective computing improves rapidly, allowing systems to process human emotions. This
                                enables systems such as conversational agents or social robots to show empathy toward
                                users. While there are various established methods to measure the empathy of humans,
                                there is no reliable and validated instrument to quantify the perceived empathy of
                                interactive systems. Thus, we developed the Perceived Empathy of Technology Scale (PETS)
                                to assess and compare how empathic users perceive technology. We followed a standardized
                                multi-phase process of developing and validating scales. In total, we invited 30 experts
                                for item generation, 324 participants for item selection, and 396 additional
                                participants for scale validation. We developed our scale using 22 scenarios with
                                opposing empathy levels, ensuring the scale is universally applicable. This resulted in
                                the PETS, a 10-item, 2-factor scale. The PETS allows designers and researchers to
                                evaluate and compare the perceived empathy of interactive systems rapidly.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>



<script setup lang="ts">
    import { defineComponent, ref } from 'vue';
    import Header from "@/components/header/Header.vue";
    import Footer from "@/components/footer/Footer.vue";
    import PrintView from '../print/PrintView.vue';
    import { routerKey, useRouter } from "vue-router";

    const router = useRouter()
    const bibtexcode = ref<HTMLDivElement | null>(null);

    function copyBibTex() {
        try {
            if (bibtexcode.value) {
                navigator.clipboard.writeText(bibtexcode.value.innerText);
            }
        } catch (error) {
            console.log("copy to clipboard not possible")
        }
    }

    function navigateTo(r: string) {
        router.push(r);
    }

    function scrollToAnchor(anchor: string) {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
</script>


<style lang="scss" scoped>
  @import "./home.scss";
</style>
