<template>
    <div class="main-container">
        <Header class="no-print" :cur-page="'print'">
        </Header>

        <div class="content container">

            <div class="settings no-print">
                <div class="howto">
                    <p>
                        Create printouts if you want to carry out a PETS evaluation with printed scales.
                        You can also customize the system description (by default "the system") as used in the items,
                        the description for the name field and the item order before printing a PETS sheet.
                        We recommend to use a randomized item sequence for each participant.
                        For evaluation, the item sequence is also printed at the bottom of the sheet.
                    </p>
                </div>
                <div class="item">
                    <input type="text" placeholder="The system" v-model="systemNaming">
                    <div class="label">System description </div>
                </div>
                <div class="item">
                    <input type="text" placeholder="Participant ID" v-model="participantId">
                    <div class="label">Name field</div>
                </div>
                <div class="item">
                    <div class="buttons">
                        <button class="btn" @click="randomizeItemOrder()">
                            <font-awesome-icon class="icon" :icon="['fa', 'shuffle']" />
                            <div class="lb">Randomize</div>
                        </button>
                        <button class="btn" @click="originalOrder()">
                            <font-awesome-icon class="icon" :icon="['fa', 'list-ol']" />
                            <div class="lb">Sort</div>
                        </button>
                    </div>
                    <div class="label">Change item order<br >
                        &#8646;
                        <span class="item-index" v-for="(s, i) in statements" :key="i">
                           {{s.index+1}}&hairsp;
                        </span>
                    </div>
                </div>
                <div class="item">
                    <div class="buttons">
                        <button class="btn printer" @click="printout()">
                            <font-awesome-icon class="icon" :icon="['fa', 'print']" />
                            Print
                        </button>
                    </div>
                </div>
                <!-- <div class="item">
                        <a href="/downloads/PETS_sorted.pdf" download>
                            <div class="download-link">
                                <font-awesome-icon class="pdf-icon" :icon="['fa', 'file-pdf']" />
                            </div>
                        </a>
                    <div class="label">Download sorted PETS</div>
                </div> -->

            </div>

            <div class="no-print spacer top">
                <p>printout</p>
            </div>

            <div class="print-page" id="print-page">
                <div class="id-box">
                    <div class="box">
                        ......................................................................
                    </div>
                    <div class="label">
                        {{ participantId }}
                    </div>
                </div>
                <div class="instructions">
                    <h2>Task</h2>
                    Please use the following statements to describe your experience with {{systemNaming}}.
                    <br />
                    Mark one point on each scale to indicate your agreement with each statement.
                    <!--Drag the sliders to indicate your agreement with each statement.-->
                </div>

                <div class="scale">
                    <div class="item" v-for="(s,i) in statements" :key="i">
                        <div class="statement">
                            {{s.dynText}}
                        </div>
                        <div class="slider">
                            <div :class="'step s'+i" v-for="i in 101" :key="i">
                            </div>
                        </div>
                        <div class="labels">
                            <div class="label left">
                                strongly disagree
                            </div>
                            <div class="label right">
                                strongly agree
                            </div>
                        </div>
                    </div>
                </div>

                <div class="helper">
                    &#8646;
                    <span class="item-index" v-for="(s, i) in statements" :key="i">
                        {{s.index+1}}&hairsp;
                    </span>
                </div>
            </div>

            <div class="no-print spacer bottom">
                <p>printout</p>
            </div>
        </div>


        <Footer class="no-print">
        </Footer>

    </div>
</template>



<script setup lang="ts">
    import { defineComponent, ref, watch, reactive} from 'vue';
    import Header from "@/components/header/Header.vue";
    import Footer from "@/components/footer/Footer.vue";
    import esJSON from "@/assets/petsApp.json";

    export interface Statement {
        id: number,
        index: number,
        text: string,
        dynText: string
    }

    let statements: Statement[] = reactive(esJSON.statements.map((s, i)=> {
        const statement: Statement = {
            id: s.id,
            index: i,
            text: s.text,
            dynText: s.text
        }
        return statement;
    }));
    let systemNaming = ref("the system");
    let participantId = ref("Participant ID");


    watch(systemNaming, (curValue, preValue) => {
        statements.forEach(s => {

            // initial "The system"
            let t = s.text.replace("The system", systemNaming.value);
            t = t.charAt(0).toUpperCase() + t.slice(1);

            // all other "the system"s
            t = t.replace("the system", systemNaming.value);

            s.dynText = t;
        });
    });

    function randomizeItemOrder() {
        statements = statements.sort(() => Math.random() - 0.5);
    }

    function originalOrder() {
        statements.sort((a, b) => a.index - b.index);
    }

    function printout() {
        window.print();
    }

</script>


<style lang="scss" scoped>
  @import "./print.scss";
</style>
